import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IParticipantModel } from '../model/ParticipantModel';
import { RootState } from '../store';

interface IExamplePluginState {
    participants: IParticipantModel[]
}

const initialState: IExamplePluginState = {
    participants: []
}


export const examplePluginSlice = createSlice({
    name: 'ExamplePlugin',
    initialState,
    reducers: {
        addParticipant: (state, action: PayloadAction<IParticipantModel>) => {

            // filter out if it is there already
            const participants = state.participants.filter(
                participant => participant.participantId !== action.payload.participantId);

            // add new participant
            participants.push(action.payload);
            return {
                ...state,
                participants
            }
        },

        removeParticipant: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                participants: state.participants.filter(participant => participant.participantId !== action.payload)
            }
        },

        changeParticipants: (state, action: PayloadAction<IParticipantModel[]>) => {
            return {
                ...state,
                participants: action.payload
            }
        }
    }
});


export const {
    addParticipant,
    removeParticipant,
    changeParticipants
} = examplePluginSlice.actions;

export const examplePluginReducer = examplePluginSlice.reducer;

export const getParticipants = (state: RootState): IParticipantModel[] => state.examplePlugin.participants;

