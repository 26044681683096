
import './SessionDetails.css';
import { useSelector } from "react-redux";
import { getMyId, getMyName, getSessionId, isConnected } from "../../slices/SessionSlice";

export default function SessionDetails() {

    const name = useSelector(getMyName);
    const sessionID = useSelector(getSessionId);
    const participantId = useSelector(getMyId);
    const connected = useSelector(isConnected);

    return (
        <div className="contents">
            <h2>Session Details</h2>
            <ul>
                <li>Session ID : {sessionID ?? "N/A"}</li>
                <li>Local Name : {name ?? "N/A"}</li>
                <li>Local Participant ID : {participantId ?? "N/A"}</li>
                <li>Websocket Connected : {connected ? "yes": "no"} </li>
            </ul>
        </div>
    )
}
