
import './ExamplePlugin.css';
import Participants from './participants/Participants';
import SessionDetails from './session/SessionDetails';

export default function ExamplePlugin() {


    return (
        <div className="contents">
            <SessionDetails/>
            <Participants/>
        </div>
    )

}