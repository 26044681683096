

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import SocketMiddleware from './middleware/SocketMiddleware';
import { examplePluginReducer } from './slices/ExamplePluginSlice';
import { sessionReducer } from './slices/SessionSlice';



export const store = configureStore({
    reducer: {
        session: sessionReducer,
        examplePlugin: examplePluginReducer 
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(SocketMiddleware)
})

export type RootState = ReturnType<typeof store.getState>

