import { ISessionModel, sessionsEqual } from "../model/SessionModel";
import { addParticipant, changeParticipants, removeParticipant } from "../slices/ExamplePluginSlice";
import { changeConnection } from "../slices/SessionSlice";


const socketMiddleware = () => {
    let socketSession: WebSocket | undefined;
    let currentSessionModel: ISessionModel | undefined;
    let clearTimer: any;


    console.log("WS is " + process.env.REACT_APP_WEB_SOCKET_URL + ".");

    const openWebSocket = (store: any, sessionModel: ISessionModel) => {
        socketSession = new WebSocket("wss://" + process.env.REACT_APP_WEB_SOCKET_URL + "?sessionId="
            + sessionModel.sessionId
            + "&name=" + sessionModel.name
            + "&participantId=" + sessionModel.participantId);

        socketSession.onopen = onConnect(store);
        socketSession.onmessage = onMessage(store);
        socketSession.onerror = onError(store);
        socketSession.onclose = onClose(store);

    }

    const onMessage = (store: any) => (event: any) => {
        const eventData = JSON.parse(event.data);

        console.log(eventData);
        if (eventData.state !== undefined && eventData.state.participants !== undefined) {

            store.dispatch(changeParticipants(eventData.state.participants));
        }
        if (eventData.delete !== undefined && eventData.delete.participants !== undefined) {
            for (let participant in eventData.delete.participants) {
                store.dispatch(removeParticipant(eventData.delete.participants[participant].participantId));

            }
        }
        if (eventData.change !== undefined && eventData.change.participants !== undefined) {
            for (let participant in eventData.change.participants) {
                store.dispatch(addParticipant(eventData.change.participants[participant]));
            }

        }
    }

    const onError = (store: any) => (event: any) => {
        console.log('Socket Error: ', event);
    }

    const onClose = (store: any) => () => {
        store.dispatch(changeConnection(false));
        clearPingTimer();
    };

    const onConnect = (store: any) => (event: any) => {
        if (!socketSession) return;

        store.dispatch(changeConnection(true));

        requestState();
        setPingTimer();
    }

    const ping = async () => {
        if (!socketSession) return;
        await sendToWebsocket("ping");
        setPingTimer();
    }

    const setPingTimer = () => {
        clearTimer = setTimeout(ping, 5000);
    }

    const clearPingTimer = () => {
        if (clearTimer) {
            clearTimeout(clearTimer);
            clearTimer = undefined;
        }
    }

    const requestState = async () => {
        if (!socketSession) return;
        await sendToWebsocket("state");
    }

    const sendToWebsocket = async (action: string) => {
        if (!socketSession) return;

        const data = { action: "message", subAction: action };
        await socketSession.send(JSON.stringify(data));
    }

    return (store: any) => (next: any) => (action: any) => {

        switch (action.type) {
            case "WS_CONNECT":
                if (socketSession) {
                    if (currentSessionModel !== undefined &&
                        sessionsEqual(action.sessionModel, currentSessionModel)) {

                        return;
                    };

                    socketSession.close();
                    socketSession = undefined;

                }

                currentSessionModel = action.sessionModel;

                openWebSocket(store, action.sessionModel)
                break;

            default:
                return next(action);
        }
    };
}




export default socketMiddleware();