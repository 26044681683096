
import './Participants.css';
import { useSelector } from "react-redux";
import { getParticipants } from '../../slices/ExamplePluginSlice';

export default function Participants() {

    const participants = useSelector(getParticipants);


    const participantList = participants.map((participant) => 
     <li key={participant.participantId}>{participant.participantId} - {participant.name}</li>
    );


    return (
        <div className="contents">
            <h2>Participants</h2>
            <ul>
                {participantList}
            </ul>
        </div>
    )
}
