import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISessionModel } from '../model/SessionModel';
import { RootState } from '../store';



interface ISessionState {
    id: string,
    connected: boolean,
    meName: string,
    meId: string,
}

const initialState: ISessionState = {
    id: "?",
    connected: false,
    meName: "",
    meId: "",
}


export const sessionSlice = createSlice({
    name: 'Session',
    initialState,
    reducers: {
        updateMe: (state, action: PayloadAction<ISessionModel>) => {
            state.id = action.payload.sessionId;
            state.meName = action.payload.name;
            state.meId=  action.payload.participantId;
            return state;
        },
        changeConnection (state, action: PayloadAction<boolean>) {
            state.connected = action.payload;
        },
       
    }
});

export const {
    updateMe,
    changeConnection,
} = sessionSlice.actions;

export const sessionReducer = sessionSlice.reducer;

export const getMyName = (state: RootState): string => state.session.meName;
export const getMyId = (state: RootState): string => state.session.meId;
export const getSessionId = (state: RootState): string => state.session.id;
export const isConnected = (state: RootState): boolean => state.session.connected;