
export interface ISessionModel {
    sessionId: string,
    name: string,
    participantId: string
}

export function sessionsEqual(a: ISessionModel, b: ISessionModel) {
    return a.sessionId === b.sessionId
        && a.name === b.name
        && a.participantId === b.participantId;
}